/*******************************
    User Variable Overrides
*******************************/


.ui.statistics .statistic > .label,
.ui.statistic > .label {
  text-transform: none;
  text-align: left;
      font-size: 22px;
      font-weight: bolder;
      line-height: 1;
}


.ui.statistics .statistic > .value,
.ui.statistic > .value {
    font-size: 60px !important;
    font-weight: bold;
  color: @vertSave;
}

.ui.horizontal.statistics .statistic > .value,
.ui.horizontal.statistic > .value {
  font-size: 55px !important;
}