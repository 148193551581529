/*******************************
         Site Overrides
*******************************/


@font-face {
  font-family: 'GothamRounded';
  src: url('./GothamRounded-Book.otf') format('opentype');
}

h1 {
  position: relative;
  font-size: 28px;
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 30px;
}

h1::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: @vertSave;
}

h2 {
  font-size: 20px;
  line-height: 1.5;
  margin-left: 0;
  padding-left: 10px;
}

a {
  color: #00bfb2;

}
