
.layout_app_with_sidebar {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto;
}

.layout_app_without_sidebar {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
    /*display: grid;*/
    /*grid-template-columns: 1fr;*/
    /*grid-template-rows: auto;*/
}

.side_app {
    padding-top: 0px;
    overflow-y: auto;
    grid-row: 1  / span 2;
    grid-column: 1;
    background-color: #0F4C81;
}

.main_app {
    overflow-y: auto;
    height: 100vh;
    background-color: #F8F9FC;
    grid-row: 1;
    grid-column: 2;
    transition: margin-left 0.4s ease-in-out;
}

.menu2 {
    margin: 0;
}

.menuItem {
    margin: 2px !important;
}

.menu2 .active {
    color: white !important;
    font-weight: bolder;
}

a {
    cursor: pointer;
}

.slide-in-out {
    transition: transform 0.4s ease-in-out;
    transform: rotateY(90deg);
    opacity: 0;
}

.slide-in-out.show {
    transform: translateZ(0);
    opacity: 1;
}

.detail-link {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto; /* Cette règle aidera à pousser le contenu vers le bas */
}

.mix-blend-mode {
    mix-blend-mode: multiply;
}

/* */
/* home page*/
/* */
.create-inter-cta {
    border-radius: 10px;
    border: 2px solid #00BFB2;
    background: #FFF;
    height: 295px;
    flex-shrink: 0;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-inter-content {
    text-align: center;
}

.shop-card {
    border-radius: 10px;
    border: 2px solid #00BFB2;
    background: #FFF;
    width: 100%;
    height: 295px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.08);
}

.shop-card-title {
    background: #00BFB2;
    color: white;
    text-align: center;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 18px;
}

.card-info ul {
    list-style: none;
    padding: 0;
}

.card-info ul li {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-weight: bolder;
}

.card-info ul li .icon {
    margin-right: 10px;
}


.stat-card {
    border-radius: 10px;
    background: #FFF;
    height: 150px;
    padding-top: 25px;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.08);
    justify-content: center;
}

.stat-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.stat-description {
    margin-top: 10px;
    font-size: 2em;
    font-weight: bolder;
}

.big-number {
    font-size: 60px;
    font-weight: bold;
    color: #00BFB2;
    line-height: 1;
}

.text-stat {
    font-size: 22px;
    margin-left: 10px;
    font-weight: bolder;
    line-height: 1;
}

.select-product-type-component-card {
    padding: 50px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border: 2px solid #FFF !important;
}

.selected-product-type-component {
    margin: 0px !important;
    padding: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    vertical-align: middle;
    border: 2px solid #FFF !important;
    width: 200px;
}

.select-product-type-component-card:hover {
    border: 2px solid #00BFB2 !important;
}

.select-product-type-component-text {
    font-size: 18px;
    font-weight: bolder;
    margin-top: 20px;
}

.selected-product-type-component-text {
    font-size: 18px;
    font-weight: bolder;
    margin-top: 20px;
}

.selected {
    border: 2px solid #00BFB2 !important;
}

.login-card {
    border: 2px solid #00BFB2 !important;
    border-radius: 10px;
}

.color-card-selected {
    border: 1px solid #00BFB2 !important;
    color: black;
    text-decoration: none;
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.06) !important;
}

.color-card {
    color: black;
    text-decoration: none;
    border: 1px solid #ffffff !important;
    /*border-radius: 10px !important;*/
    /*border: 2px solid #00BFB2 !important;*/
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.06) !important;
}

.imei-input-info-text {
    color: #7C7C7C;
    margin-left: 10px;
    margin-top: 6px;
}

.imei-message-box {
    margin-left: 20px;
    width: 100%;
    height: 100%;
    background: #0F4C81 !important;
    color: white !important;
    border-radius: 10px !important;
}

.resume-card {
    border-radius: 10px;
    border: 2px solid #00BFB2;
    background: #FFF;
    width: 250px;
    height: 100%;
    padding-bottom: 30px;
}

.resume-card-title {
    background: #00BFB2;
    color: white;
    text-align: center;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
}

.color-white {
    border-right: 1px solid lightgrey !important;
}

.repair-image {
    background: white !important;
    margin-top: 20px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    margin-bottom: 10px !important;
}

.repair-card-text {
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 35px !important;
    color: black;
    text-decoration: none !important;
    text-align: center;
}

.repair-card-price {
    background: #e8e6e6;
    margin-bottom: 30px;
}


.create-inter-bloc-gauche {
    width: calc(100% - 280px);
    float: left;
}

.create-inter-bloc-droite {
    width: 260px;
    float: right;
    margin-left: 20px;
}