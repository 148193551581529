/*******************************
    User Variable Overrides
*******************************/

.ui.cards .card .image img, .ui.card .image .img {
  max-height: 150px;
   text-decoration: none !important;
   margin-bottom: 0px;
}




