/*******************************
         Site Overrides
*******************************/

.ui.inverted.green.segment {
  background-color: @vertSave !important;
  color: @white !important;
}

.ui.green.segment:not(.inverted) {
  border-top: @coloredBorderSize solid @vertSave !important;
}