/*******************************
         Site Overrides
*******************************/

.ui.secondary.vertical.pointing.menu .item {
    color: white;
}

.ui.secondary.vertical.pointing.menu:hover .item {
    color: @bleuClairSave;
}

.ui.secondary.vertical.pointing.menu .active.item {
  border-color: @vertSave;
}

.ui.menu {
  background: @lightBodyBackground;
    border-radius: 0px;
}

.ui.menu .item{
    color: black;
}

.ui.menu .item:hover{
    background: rgba(0,0,0,0) !important;
}

.ui.pagination.menu .item{
    background: white;
}

.ui.pagination.menu .active.item{

}

.ui.borderless.text.menu {
    margin-left: 0px;
}

.ui.pointing.secondary.menu .active.item {
    color: @vertSave;
    border-color: @vertSave;
}