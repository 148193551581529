/*******************************
         Site Overrides
*******************************/

.ui.block.header {
  background-color: @vertSave !important;
  color: @white !important;
  font-weight: 200;
}

.ui.top.attached.header {
    border-radius: 1rem 1rem 0em 0em;
}