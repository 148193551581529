/*******************************
         Site Overrides
*******************************/

.ui.green.label {
  background-color: @vertSave !important;
  border-color: @vertSave !important;
  cursor: default;
}

.ui.green.label:hover {
  background-color: @vertSave !important;
  border-color: @vertSave !important;
}